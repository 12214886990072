import BaseHeader from '@/components/BaseHeader'
import Countdown from './components/Countdown'
import fetchGiveawaysMixin from '@/mixins/fetch-giveaways'
import gaEventMixin from '@/mixins/ga_event'

export default {
  mixins: [gaEventMixin, fetchGiveawaysMixin],
  data() {
    return {
      isLoading: true,
      isEnded: false,
      isActive: false,
      baseGiveawayToken: '6Ys1XZI',
      baseGiveaway: null,
      baseGiveawayInterval: null,
      // videoSrc: require('../../assets/themes/polaris-bf2042/video/POL_BF_60_4K_11242021_FINAL_Compressed.mp4'),
      regions: [
        { id: 'global', label: 'Global', ariaLabel: 'Global Giveaways', imageStyle: 'esrb' },
        { id: 'americas', label: 'The Americas', ariaLabel: 'Giveaways for The Americas', imageStyle: 'esrb' },
        { id: 'europe', label: 'Europe', ariaLabel: 'Giveaways for Europe', imageStyle: 'pegi' },
        { id: 'asia', label: 'Asia', ariaLabel: 'Giveaways for Asia', imageStyle: 'default' },
        { id: 'japan', label: 'Japan', ariaLabel: 'Giveaways for Japan', imageStyle: 'default' },
        { id: 'south_korea', label: 'South Korea', ariaLabel: 'Giveaways for South Korea', imageStyle: 'default' }
      ]
    }
  },
  watch: {
    isEnded() {
      if (this.isEnded) {
        clearInterval(this.baseGiveawayInterval)
      }
    }
  },
  async created() {
    const giveawayFetchList = await this.fetchGiveawaysByIdTokens([this.baseGiveawayToken], 300)
    this.baseGiveaway = giveawayFetchList[0]

    this.baseGiveawayCheck()
    this.baseGiveawayInterval = setInterval(this.baseGiveawayCheck, 1000)
    this.isLoading = false
  },
  beforeDestroy() {
    clearInterval(this.baseGiveawayInterval)
  },
  methods: {
    baseGiveawayCheck() {
      // NOTE: it is assumed that all giveaways have the same start/end times
      // so we are just checking 'baseGiveawayToken' to trigger page states.
      // @see src/router/ea/ea-vgas-routes.js for its definition
      this.isEnded = this.baseGiveaway?.isEnded
      this.isActive = this.baseGiveaway?.isActive
    },
    jumpToMain() {
      this.trackEvent('jump_to_main')
      this.$helpers.scrollToId('main')
    }
  },
  components: {
    BaseHeader,
    Countdown
  }
}
